.autocomplete__wrapper {
  position: relative;
}

.autocomplete__hint,
.autocomplete__input {
  appearance: none;
  border: 2px solid #0b0c0c;
  border-radius: 0; /* Safari 10 on iOS adds implicit border rounding. */
  box-sizing: border-box;
  margin-bottom: 0; /* BUG: Safari 10 on macOS seems to add an implicit margin. */
  line-height: 1.25;
  height: 2.5rem;
  width: 100%;
}

.autocomplete__input {
  background-color: transparent;
  position: relative;
}

.autocomplete__hint {
  color: #505a5f;
  position: absolute;
}

.autocomplete__input--default {
  padding: 5px;
}

.autocomplete__input--focused {
  outline: 3px solid #fd0;
  outline-offset: 0;
  box-shadow: inset 0 0 0 2px;
}

.autocomplete__input--show-all-values {
  padding: 5px 35px 5px 5px; /* Space for arrow. Other padding should match .autocomplete__input--default. */
  cursor: pointer;
}

.autocomplete__dropdown-arrow-down {
  z-index: -1;
  display: inline-block;
  position: absolute;
  right: 8px;
  width: 24px;
  height: 24px;
  top: 10px;
}

.autocomplete__menu {
  background-color: #ffffff;
  border: 2px solid #0b0c0c;
  border-top: 0;
  color: #0b0c0c;
  margin: 0;
  max-height: 342px;
  overflow-x: hidden;
  padding: 0;
  width: 100%;
  width: calc(100% - 4px);
}

.autocomplete__menu--visible {
  display: block;
}

.autocomplete__menu--hidden {
  display: none;
}

.autocomplete__menu--overlay {
  box-shadow: rgba(0, 0, 0, 0.256863) 0px 2px 6px;
  left: 0;
  position: absolute;
  top: 100%;
  z-index: 100;
}

.autocomplete__menu--inline {
  position: relative;
}

.autocomplete__option {
  border-bottom: solid #b1b4b6;
  border-width: 1px 0;
  cursor: pointer;
  display: block;
  position: relative;
}

.autocomplete__option > * {
  pointer-events: none;
}

.autocomplete__option:first-of-type {
  border-top-width: 0;
}

.autocomplete__option:last-of-type {
  border-bottom-width: 0;
}

.autocomplete__option--odd {
  background-color: #f3f2f1;
}

.autocomplete__option--focused,
.autocomplete__option:hover {
  background-color: #1d70b8;
  border-color: #1d70b8;
  color: white;
  outline: none;
}

@media (-ms-high-contrast: active), (forced-colors: active) {
  .autocomplete__menu {
    border-color: FieldText;
  }

  .autocomplete__option {
    background-color: Field;
    color: FieldText;
  }

  .autocomplete__option--focused,
  .autocomplete__option:hover {
    forced-color-adjust: none; /* prevent backplate from obscuring text */
    background-color: Highlight;
    border-color: Highlight;
    color: HighlightText;

    /* Prefer SelectedItem / SelectedItemText in browsers that support it */
    background-color: SelectedItem;
    border-color: SelectedItem;
    color: SelectedItemText;
    outline-color: SelectedItemText;
  }
}

.autocomplete__option--no-results {
  background-color: #f3f2f1;
  color: #505a5f;
  cursor: not-allowed;
}

.autocomplete__hint,
.autocomplete__input,
.autocomplete__option {
  font-size: 1rem;
  font-weight: 400;
}

.autocomplete__hint,
.autocomplete__option {
  padding: 5px;
}

@media (min-width: 641px) {
  .autocomplete__hint,
  .autocomplete__input,
  .autocomplete__option {
    font-size: 1.1875rem;
    line-height: 1.3157894737;
  }
}
